import { Grid, Pagination, Stack } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { productItem } from "../../pages/Products/productList";
import { service, scrollToTop } from "../../services/hooks";

interface CustomPaginationProps {
  category: productItem[];
  pageSize: number;
  setPageProducts(p: productItem[]): void;
  uniqueKey: string;
  pageRef: any;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  category,
  pageSize,
  setPageProducts,
  uniqueKey,
  pageRef,
}) => {
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    page: 1,
    to: pageSize,
  });

  // const timer = () => {
  //   setTimeout(() => {
  //     incrementPage();
  //   }, 600);
  // };

  // const timer2 = () => {
  //   setTimeout(() => {
  //     decrementPage();
  //   }, 500);
  // };

  useEffect(() => {
    service
      .getData({
        from: pagination.from,
        to: pagination.to,
        products: category,
      })
      .then((response: any) => {
        setPagination({ ...pagination, count: response.count });
        setPageProducts(response.data);
      });
  }, [pagination.from, pagination.to, category]);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    const from = (value - 1) * pageSize;
    const to = (value - 1) * pageSize + pageSize;
    setPagination({ ...pagination, from: from, to: to, page: value });
  };

  // const decrementPage = () => {
  //   const from = (pagination.page - 2) * pageSize;
  //   const to = (pagination.page - 2) * pageSize + pageSize;
  //   setPagination({
  //     ...pagination,
  //     from: from,
  //     to: to,
  //     page: pagination.page - 1,
  //   });
  // };

  // const incrementPage = () => {
  //   const from = pagination.page * pageSize;
  //   const to = pagination.page * pageSize + pageSize;
  //   setPagination({
  //     ...pagination,
  //     from: from,
  //     to: to,
  //     page: pagination.page + 1,
  //   });
  // };

  return (
    // <Stack alignItems="center" style={{ marginTop: "2rem" }}>
    <Grid
      container
      style={{
        marginTop: "2rem",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <Grid item>
        <NavigateButtonStyled
          disabled={pagination.page === 1 ? true : false}
          onClick={() => {
            scrollToTop(pageTopRef);
            timer2();
          }}
        >
          <NavigateBefore fontSize="small" />
        </NavigateButtonStyled>
      </Grid> */}
      <Grid item>
        <Pagination
          key={uniqueKey}
          shape="rounded"
          color="primary"
          count={Math.ceil(pagination.count / pageSize)}
          onChange={handlePageChange}
          onClick={() => scrollToTop(pageRef, true)}
          page={pagination.page}
          // hideNextButton
          // hidePrevButton
        />
      </Grid>
      {/* <Grid item>
        <NavigateButtonStyled
          disabled={pagination.page === pageCount ? true : false}
          value={pagination.page + 1}
          onClick={() => {
            scrollToTop(pageTopRef);
            timer();
          }}
        >
          <NavigateNext fontSize="small" />
        </NavigateButtonStyled>
      </Grid> */}
    </Grid>
    // </Stack>
  );
};

export default CustomPagination;
