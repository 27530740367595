import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import "@fontsource/poppins";
import "@fontsource/roboto";
import "@fontsource-variable/open-sans";

export const themeColor = {
  white: "#fff",
  darkBlue: "#14213d",
  blue: "#2a3c67",
  lightBlue: "#476ab2",
  red: "#da3a3d",
  darkRed: "#b76366",
  lightGreen: "#f1faee",
  yellow: "#fca311",
  lightGrey: "#f0f0ef",
  grey: "#d4d4d4",
  darkGrey: "#777",
  lightWhiteGrey: "#f2f4f5",
  whiteGrey: "#e9e9e9",
  darkGreyGreen: "#767669",
  greyGreen: "#8d8e86",
  lightGreyGreen: "#b7b9b",
  lightBlack: "#555555",
};

const points = createTheme({
  breakpoints: {
    values: {
      xs: 0, // extra-small
      sm: 600, // small
      md: 960, // medium
      lg: 1280, // large
      xl: 1920, // extra-large
    },
  },
});

let theme = createTheme({
  breakpoints: { ...points.breakpoints },
  palette: {
    primary: {
      main: themeColor.blue,
      light: themeColor.lightBlue,
      dark: themeColor.darkBlue,
    },
    secondary: {
      main: themeColor.red,
    },
    background: {
      default: themeColor.lightBlue,
    },
  },
  typography: {
    fontFamily: 'Poppins',

    // h3: {
    //   fontWeight: 600,
    // },

    // h5: {
    //   fontWeight: 600,
    // },

    body1: {
      fontWeight: 500,
    },
  },
});

theme = responsiveFontSizes(theme);

export { theme };
