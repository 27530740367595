import styled from "@emotion/styled";
import { themeColor } from "../../styles/Theme";
import { Typography } from "@mui/material";

const TitleStyled = styled(Typography)`
  font-size: 35px;

  @media (min-width: 960px) {
    font-size: 40px;
  }

  @media (min-width: 1121px) {
    font-size: 45px;
  }
`;

const SubTitleStyled = styled(Typography)`
  font-size: 15px;
  color: ${themeColor.darkGrey};

  @media (min-width: 960px) {
    font-size: 16px;
  }
`;

const LineIcon = styled.span`
  color: #bababa;
`;
export { TitleStyled, LineIcon, SubTitleStyled };
