import styled from "@emotion/styled";

const AppContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  // height: 100vh;
  // width: 100vw;
  overflow: auto;
`;
const Main = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
`;
const AppBody = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  & > div {
    // &::-webkit-scrollbar {
    //   width: 0;
    //   appearance: none;
    // }
    overflow-y: auto;
    overflow-x: auto;
    height: inherit;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

const MenuOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: -webkit-fill-available !important;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 0;
  left: 0;

  @media (min-width: 992px) {
    display: none !important;
  }
`;

export { AppContainer, Main, AppBody, MenuOverlay };
