import React, { useEffect, useRef, useState } from "react";
import {
  AppHeaderImg,
  AppImg1,
  AppImg2,
  AppImg3,
  AppImg4,
  AppImg5,
  AppImg6,
  AppImg7,
  AppImg8,
} from "../../components/Image";
import CustomContainer from "../../components/CustomContainer";
import { ImgDiv } from "./styles";
import { Grid, Stack } from "@mui/material";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { SEO } from "../../services/hooks";

const Applications = () => {
  const pageTopRef = useRef(null);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <div>
      <SEO title="Applications" description="" />
      <div style={{ zIndex: 100 }} ref={pageTopRef}>
        <Header />
      </div>
      {/* <AppHeaderImg /> */}
      <div
        style={{ width: "100%", background: "#f0f0ef", position: "relative" }}
      >
        <CustomContainer className="App">
          <Stack
            spacing={
              windowSize >= 1231
                ? 6
                : windowSize >= 960 && windowSize < 1231
                ? 4
                : 2
            }
          >
            <ImgDiv>
              <AppImg1 />
            </ImgDiv>
            <ImgDiv>
              <AppImg2 />
            </ImgDiv>
            <ImgDiv>
              <AppImg3 />
            </ImgDiv>
            <ImgDiv>
              <AppImg4 />
            </ImgDiv>
            <ImgDiv>
              <AppImg5 />
            </ImgDiv>
            <ImgDiv>
              <AppImg6 />
            </ImgDiv>
            <ImgDiv>
              <AppImg7 />
            </ImgDiv>
            <ImgDiv>
              <AppImg8 />
            </ImgDiv>
          </Stack>
        </CustomContainer>
      </div>
      <Footer pageTopRef={pageTopRef} />
    </div>
  );
};

export default Applications;
