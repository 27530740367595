import React from "react";

import LogoImg from "../assets/logo.png";
import HomeImg1 from "../assets/homeInfo1.jpg";
import HomeImg2 from "../assets/homeInfo2.jpg";
import HomeImg3 from "../assets/homeInfo3.jpg";
import HomeImg4 from "../assets/homeProduct1.png";
import HomeImg5 from "../assets/homeProduct2.png";
import HomeImg6 from "../assets/homeProduct3.png";
import HomeImg7 from "../assets/homeProduct4.png";
import HomeImg8 from "../assets/homeProduct5.png";
import HomeImg9 from "../assets/homeProduct6.png";
import HomeImg10 from "../assets/app1.png";
import HomeImg11 from "../assets/app2.png";
import HomeImg12 from "../assets/app3.png";
import HomeImg13 from "../assets/app4.png";
import HomeImg14 from "../assets/app5.png";
import HomeImg15 from "../assets/app6.png";
import AboutUs from "../assets/aboutUs.png";
import ProductHeader from "../assets/All Seals 01.jpg";
import Product1 from "../assets/seal 1.png";
import Product2 from "../assets/seal 2.png";
import Product3 from "../assets/seal 3.png";
import Product4 from "../assets/seal 4.png";
import Product5 from "../assets/seal 5.png";
import Product6 from "../assets/seal 6.png";
import Product7 from "../assets/seal 7.png";
import Product8 from "../assets/seal 8.png";
import Product9 from "../assets/seal 9.png";
import Product10 from "../assets/seal 10.png";
import Product11 from "../assets/seal 11.png";
import Product12 from "../assets/seal 12.png";
import Product13 from "../assets/seal 13.png";
import Product14 from "../assets/seal 14.png";
import Product15 from "../assets/seal 15.png";
import Product16 from "../assets/seal 16.png";
import Product17 from "../assets/seal 17.png";
import Product18 from "../assets/seal 18.png";
import Product19 from "../assets/seal 19.png";
import ProductDes1 from "../assets/productDes1.jpg";
import ProductDes2 from "../assets/productDes2.jpg";
import ProductDes3 from "../assets/productDes3.jpg";
import ProductDes4 from "../assets/productDes4.jpg";
import ProductDes5 from "../assets/productDes5.jpg";
import ProductDes6 from "../assets/productDes6.jpg";
import ProductDes7 from "../assets/productDes7.jpg";
import ProductDes8 from "../assets/productDes8.jpg";
import ProductDes9 from "../assets/productDes9.jpg";
import ProductDes10 from "../assets/productDes10.jpg";
import ProductDes11 from "../assets/productDes11.jpg";
import ProductDes12 from "../assets/productDes12.jpg";
import ProductDes13 from "../assets/productDes13.jpg";
import ProductDes14 from "../assets/productDes14.jpg";
import ProductDes15 from "../assets/productDes15.jpg";
import ProductDes16 from "../assets/productDes16.jpg";
import ProductDes17 from "../assets/productDes17.jpg";
import ProductDes18 from "../assets/productDes18.jpg";
import ProductDes19 from "../assets/productDes19.jpg";
import AppHeader from "../assets/Silicon.png";
import App1 from "../assets/More Products 001.jpg";
import App2 from "../assets/More Products 002.jpg";
import App3 from "../assets/More Products 003.jpg";
import App4 from "../assets/More Products 004.jpg";
import App5 from "../assets/More Products 005.jpg";
import App6 from "../assets/More Products 006.jpg";
import App7 from "../assets/More Products 007.jpg";
import App8 from "../assets/More Products 008.jpg";
import ContactUs from "../assets/contactUs.jpg";
import SiliconBg from "../assets/Silicon Background 01.jpg";
import Qr1 from "../assets/Kelvin QR.jpg";
import Qr2 from "../assets/KK QR.jpg";

export const Logo = (props: any) => <img alt="" {...props} src={LogoImg} />;

export const HomeInfo1 = (props: any) => (
  <img alt="" {...props} src={HomeImg1} />
);
export const HomeInfo2 = (props: any) => (
  <img alt="" {...props} src={HomeImg2} />
);
export const HomeInfo3 = (props: any) => (
  <img alt="" {...props} src={HomeImg3} />
);

export const HomeProduct1 = (props: any) => (
  <img alt="" {...props} src={HomeImg4} />
);

export const HomeProduct2 = (props: any) => (
  <img alt="" {...props} src={HomeImg5} />
);

export const HomeProduct3 = (props: any) => (
  <img alt="" {...props} src={HomeImg6} />
);

export const HomeProduct4 = (props: any) => (
  <img alt="" {...props} src={HomeImg7} />
);

export const HomeProduct5 = (props: any) => (
  <img alt="" {...props} src={HomeImg8} />
);

export const HomeProduct6 = (props: any) => (
  <img alt="" {...props} src={HomeImg9} />
);

export const HomeApp1 = (props: any) => (
  <img alt="" {...props} src={HomeImg10} />
);

export const HomeApp2 = (props: any) => (
  <img alt="" {...props} src={HomeImg11} />
);

export const HomeApp3 = (props: any) => (
  <img alt="" {...props} src={HomeImg12} />
);

export const HomeApp4 = (props: any) => (
  <img alt="" {...props} src={HomeImg13} />
);

export const HomeApp5 = (props: any) => (
  <img alt="" {...props} src={HomeImg14} />
);

export const HomeApp6 = (props: any) => (
  <img alt="" {...props} src={HomeImg15} />
);

export const AboutUsImg = (props: any) => (
  <img alt="" {...props} src={AboutUs} />
);

export const ProductHeaderImg = (props: any) => (
  <img alt="" {...props} src={ProductHeader} />
);

export const SealImgDes1 = (props: any) => (
  <img alt="" {...props} src={ProductDes1} />
);
export const SealImgDes2 = (props: any) => (
  <img alt="" {...props} src={ProductDes2} />
);
export const SealImgDes3 = (props: any) => (
  <img alt="" {...props} src={ProductDes3} />
);
export const SealImgDes4 = (props: any) => (
  <img alt="" {...props} src={ProductDes4} />
);
export const SealImgDes5 = (props: any) => (
  <img alt="" {...props} src={ProductDes5} />
);
export const SealImgDes6 = (props: any) => (
  <img alt="" {...props} src={ProductDes6} />
);
export const SealImgDes7 = (props: any) => (
  <img alt="" {...props} src={ProductDes7} />
);
export const SealImgDes8 = (props: any) => (
  <img alt="" {...props} src={ProductDes8} />
);
export const SealImgDes9 = (props: any) => (
  <img alt="" {...props} src={ProductDes9} />
);
export const SealImgDes10 = (props: any) => (
  <img alt="" {...props} src={ProductDes10} />
);
export const SealImgDes11 = (props: any) => (
  <img alt="" {...props} src={ProductDes11} />
);
export const SealImgDes12 = (props: any) => (
  <img alt="" {...props} src={ProductDes12} />
);
export const SealImgDes13 = (props: any) => (
  <img alt="" {...props} src={ProductDes13} />
);
export const SealImgDes14 = (props: any) => (
  <img alt="" {...props} src={ProductDes14} />
);
export const SealImgDes15 = (props: any) => (
  <img alt="" {...props} src={ProductDes15} />
);
export const SealImgDes16 = (props: any) => (
  <img alt="" {...props} src={ProductDes16} />
);
export const SealImgDes17 = (props: any) => (
  <img alt="" {...props} src={ProductDes17} />
);
export const SealImgDes18 = (props: any) => (
  <img alt="" {...props} src={ProductDes18} />
);
export const SealImgDes19 = (props: any) => (
  <img alt="" {...props} src={ProductDes19} />
);

export const SealImg1 = (props: any) => (
  <img alt="" {...props} src={Product1} />
);
export const SealImg2 = (props: any) => (
  <img alt="" {...props} src={Product2} />
);
export const SealImg3 = (props: any) => (
  <img alt="" {...props} src={Product3} />
);
export const SealImg4 = (props: any) => (
  <img alt="" {...props} src={Product4} />
);
export const SealImg5 = (props: any) => (
  <img alt="" {...props} src={Product5} />
);
export const SealImg6 = (props: any) => (
  <img alt="" {...props} src={Product6} />
);
export const SealImg7 = (props: any) => (
  <img alt="" {...props} src={Product7} />
);
export const SealImg8 = (props: any) => (
  <img alt="" {...props} src={Product8} />
);
export const SealImg9 = (props: any) => (
  <img alt="" {...props} src={Product9} />
);
export const SealImg10 = (props: any) => (
  <img alt="" {...props} src={Product10} />
);
export const SealImg11 = (props: any) => (
  <img alt="" {...props} src={Product11} />
);
export const SealImg12 = (props: any) => (
  <img alt="" {...props} src={Product12} />
);
export const SealImg13 = (props: any) => (
  <img alt="" {...props} src={Product13} />
);
export const SealImg14 = (props: any) => (
  <img alt="" {...props} src={Product14} />
);
export const SealImg15 = (props: any) => (
  <img alt="" {...props} src={Product15} />
);
export const SealImg16 = (props: any) => (
  <img alt="" {...props} src={Product16} />
);
export const SealImg17 = (props: any) => (
  <img alt="" {...props} src={Product17} />
);
export const SealImg18 = (props: any) => (
  <img alt="" {...props} src={Product18} />
);
export const SealImg19 = (props: any) => (
  <img alt="" {...props} src={Product19} />
);

export const AppHeaderImg = (props: any) => (
  <img alt="" {...props} src={AppHeader} />
);
export const AppImg1 = (props: any) => <img alt="" {...props} src={App1} />;
export const AppImg2 = (props: any) => <img alt="" {...props} src={App2} />;
export const AppImg3 = (props: any) => <img alt="" {...props} src={App3} />;
export const AppImg4 = (props: any) => <img alt="" {...props} src={App4} />;
export const AppImg5 = (props: any) => <img alt="" {...props} src={App5} />;
export const AppImg6 = (props: any) => <img alt="" {...props} src={App6} />;
export const AppImg7 = (props: any) => <img alt="" {...props} src={App7} />;
export const AppImg8 = (props: any) => <img alt="" {...props} src={App8} />;
export const ContactUsImg = (props: any) => (
  <img alt="" {...props} src={ContactUs} />
);

export const SiliconBgImg = (props: any) => (
  <img alt="" {...props} src={SiliconBg} />
);

export const QrImg1 = (props: any) => <img alt="" {...props} src={Qr1} />;

export const QrImg2 = (props: any) => <img alt="" {...props} src={Qr2} />;
