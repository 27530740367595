import React, { useEffect, useRef, useState } from "react";
import ImageSlider from "../../components/ImageSlider";
import {
  HomeApp1,
  HomeApp2,
  HomeApp3,
  HomeApp4,
  HomeApp5,
  HomeApp6,
  HomeInfo1,
  HomeInfo2,
  HomeInfo3,
  HomeProduct1,
  HomeProduct2,
  HomeProduct3,
  HomeProduct4,
  HomeProduct5,
  HomeProduct6,
  SiliconBgImg,
} from "../../components/Image";
import { Grid } from "@mui/material";
import CustomContainer from "../../components/CustomContainer";
import CustomTitle from "../../components/CustomTitle";
import CustomCard from "../../components/CustomCard";
import { AboutUsStyled, AppGrid, GridContainer } from "./styles";
import CustomButton from "../../components/CustomButton";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import { paths } from "../../configs/routes";
import Header from "../../components/Header";
import { SEO, scrollToTop } from "../../services/hooks";
import { HeaderImgDiv } from "../../utilities/common";

const slides = [<HomeInfo1 />, <HomeInfo2 />, <HomeInfo3 />];

const Home: React.FC<any> = () => {
  const navigate = useNavigate();
  const pageTopRef = useRef(null);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <div>
      <SEO
        title="Fineseal Engineering & Services"
        description="Fineseal Engineering specializes in the design, R&D, manufacturing,
          sales, and marketing of fluid mechanical seals. Thanks to our patented
          technology, modular design, and flexible production capabilities, we
          excel in offering customers comprehensive solutions to address common
          issues, including leaks, fast wear-off, and drips. We are adept at
          meeting diverse material and application requirements."
      />
      <div style={{ zIndex: 100 }} ref={pageTopRef}>
        <Header />
      </div>
      <ImageSlider slides={slides} />
      <div
        style={{ width: "100%", background: "#f2f4f5", position: "relative" }}
      >
        <CustomContainer className="ProductCenter">
          <CustomTitle
            mainTitle="Product Center"
            subTitle="High standard design of modular mechanical seal"
          />
          <GridContainer container spacing={windowSize >= 960 ? 4 : 3}>
            <Grid
              item
              xs={windowSize >= 960 ? 6 : 12}
              style={{ paddingLeft: 0 }}
            >
              <CustomCard
                isProduct
                title="For Conventional Kettle"
                description="Non-directional transmission. Specific pressure of the spring is adjustable to accomodate varying working pressures. Balanced design."
                image={<HomeProduct1 />}
                route={paths.seal1}
                pageTopRef={pageTopRef}
              />
            </Grid>
            <Grid
              item
              xs={windowSize >= 960 ? 6 : 12}
              style={{ paddingLeft: windowSize >= 960 ? "" : 0 }}
            >
              <CustomCard
                isProduct
                title="For Modular Kettle"
                description="Modular design with non-directional transmission. New structure eliminates the need for a glass-lined water tank. Corrosion resistance."
                image={<HomeProduct2 />}
                route={paths.seal9}
                pageTopRef={pageTopRef}
              />
            </Grid>
            <Grid
              item
              xs={windowSize >= 960 ? 6 : 12}
              style={{ paddingLeft: 0 }}
            >
              <CustomCard
                isProduct
                title="For Modular Kettle"
                description=""
                image={<HomeProduct3 />}
                route={paths.seal14}
                pageTopRef={pageTopRef}
              />
            </Grid>
            <Grid
              item
              xs={windowSize >= 960 ? 6 : 12}
              style={{ paddingLeft: windowSize >= 960 ? "" : 0 }}
            >
              <CustomCard
                isProduct
                title="One-piece Belt Frame"
                description="Suitable for high speed occasions. Integrated sealed frame. Higher design accuracy."
                image={<HomeProduct4 />}
                route={paths.seal15}
                pageTopRef={pageTopRef}
              />
            </Grid>
            <Grid
              item
              xs={windowSize >= 960 ? 6 : 12}
              style={{ paddingLeft: 0 }}
            >
              <CustomCard
                isProduct
                title="Mechanical Seal Auxiliary System"
                description=""
                image={<HomeProduct5 />}
                route={paths.seal18}
                pageTopRef={pageTopRef}
              />
            </Grid>
            <Grid
              item
              xs={windowSize >= 960 ? 6 : 12}
              style={{ paddingLeft: windowSize >= 960 ? "" : 0 }}
            >
              <CustomCard
                isProduct
                title="Special Working Condition Design"
                description=""
                image={<HomeProduct6 />}
                route={paths.seal16}
                pageTopRef={pageTopRef}
              />
            </Grid>
          </GridContainer>
          <CustomButton
            title="More"
            onClick={() => {
              navigate("/" + paths.products);
              scrollToTop(pageTopRef);
            }}
          />
        </CustomContainer>
      </div>
      <div style={{ position: "relative", width: "100%" }}>
        <HeaderImgDiv>
          <SiliconBgImg />
        </HeaderImgDiv>
      </div>
      <div style={{ width: "100%", background: "white", position: "relative" }}>
        <CustomContainer className="AboutUs">
          {/* <Grid container spacing={8}>
          <Grid item xs={7}> */}
          <AboutUsStyled className="header">ABOUT US</AboutUsStyled>
          <AboutUsStyled
            className="header"
            style={{ color: "black", fontWeight: "normal" }}
          >
            FINESEAL
          </AboutUsStyled>
          <AboutUsStyled className="body">
            Fineseal Engineering specializes in the design, R&D, manufacturing,
            sales, and marketing of fluid mechanical seals. Thanks to our
            patented technology, modular design, and flexible production
            capabilities, we excel in offering customers comprehensive solutions
            to address common issues, including leaks, fast wear-off, and drips.
            We are adept at meeting diverse material and application
            requirements.
          </AboutUsStyled>
          {/* <Grid container spacing={8}>
          <Grid item xs={4}> */}
          <AboutUsStyled variant="h5" className="subHeader">
            Innovative Technology
          </AboutUsStyled>
          <AboutUsStyled className="subBody">
            Patent references : ZL202022563587.0, ZL202022562733.8,
            ZL20212091194.0, ZL202120291183.2, ZL20222840809.8
          </AboutUsStyled>
          <CustomButton
            title="Read More"
            onClick={() => {
              navigate("/" + paths.aboutUs);
              scrollToTop(pageTopRef);
            }}
          />
        </CustomContainer>
      </div>
      <div
        style={{ width: "100%", background: "#f2f4f5", position: "relative" }}
      >
        <CustomContainer className="HomeApp">
          <CustomTitle
            mainTitle="Applications"
            subTitle="Profession is the foundation, service is the guarantee, and quality is the credibility"
          />
          <AppGrid container spacing={0.5} className="top">
            <Grid item xs={windowSize >= 675 ? 4 : 6}>
              <CustomCard
                title="Food Processing Industry"
                image={<HomeApp1 />}
              />
            </Grid>
            <Grid item xs={windowSize >= 675 ? 4 : 6}>
              <CustomCard title="Rubber Industry" image={<HomeApp2 />} />
            </Grid>
            <Grid item xs={4} sx={{ display: windowSize >= 675 ? "" : "none" }}>
              <CustomCard
                title="Pharmaceutical Industry"
                image={<HomeApp3 />}
              />
            </Grid>
          </AppGrid>
          <AppGrid
            container
            spacing={0.5}
            sx={{ display: windowSize >= 675 ? "none" : "" }}
          >
            <Grid item xs={6}>
              <CustomCard
                title="Pharmaceutical Industry"
                image={<HomeApp3 />}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomCard title="Refining Industry" image={<HomeApp4 />} />
            </Grid>
          </AppGrid>
          <AppGrid container spacing={0.5} className="bot">
            <Grid item xs={4} sx={{ display: windowSize >= 675 ? "" : "none" }}>
              <CustomCard title="Refining Industry" image={<HomeApp4 />} />
            </Grid>
            <Grid item xs={windowSize >= 675 ? 4 : 6}>
              <CustomCard title="Power Industry" image={<HomeApp5 />} />
            </Grid>
            <Grid item xs={windowSize >= 675 ? 4 : 6}>
              <CustomCard
                title="Petrol Chemical Industry"
                image={<HomeApp6 />}
              />
            </Grid>
          </AppGrid>
          <CustomButton
            title="More Cases"
            onClick={() => {
              navigate("/" + paths.applications);
              scrollToTop(pageTopRef);
            }}
          />
          {/* <Stack spacing={2} direction="row">

          <CustomButton title="Contact Us" />
        </Stack> */}
        </CustomContainer>
      </div>
      <Footer pageTopRef={pageTopRef} />
    </div>
  );
};

export default Home;
