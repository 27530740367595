import React, { useEffect, useState } from "react";
import CustomContainer from "../CustomContainer";
import { Box, Divider, Grid } from "@mui/material";
import { BodyStyled, HeaderStyled, OutBox } from "./styles";
import { useNavigate } from "react-router-dom";
import { QrImg1, QrImg2 } from "../Image";
import { MailOutline } from "@mui/icons-material";
import { Mailto, scrollToTop } from "../../services/hooks";
import { paths } from "../../configs/routes";

interface FooterProps {
  pageTopRef?: any;
}

const Footer: React.FC<FooterProps> = ({ pageTopRef }) => {
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <OutBox>
      <CustomContainer className="Footer">
        <Grid container spacing={0}>
          <Grid item xs={windowSize >= 960 ? 2 : 12}>
            <Box style={{ paddingBottom: "1rem" }}>
              <HeaderStyled
                onClick={() => {
                  navigate("/" + paths.aboutUs);
                  scrollToTop(pageTopRef);
                }}
              >
                ABOUT US
              </HeaderStyled>
            </Box>
          </Grid>
          <Grid item xs={windowSize >= 960 ? 4 : 12}>
            <Box style={{ paddingBottom: "1rem" }}>
              <HeaderStyled
                onClick={() => {
                  navigate("/" + paths.products);
                  scrollToTop(pageTopRef);
                }}
              >
                PRODUCTS
              </HeaderStyled>
            </Box>
            {windowSize >= 960 ? (
              <Box>
                <BodyStyled
                  gutterBottom
                  onClick={() => {
                    navigate("/" + paths.conventionalKettle);
                    scrollToTop(pageTopRef);
                  }}
                >
                  For conventional kettle
                </BodyStyled>
                <BodyStyled
                  gutterBottom
                  onClick={() => {
                    navigate("/" + paths.modularKettle);
                    scrollToTop(pageTopRef);
                  }}
                >
                  For modular kettle
                </BodyStyled>
                <BodyStyled
                  gutterBottom
                  onClick={() => {
                    navigate("/" + paths.onePieceKettle);
                    scrollToTop(pageTopRef);
                  }}
                >
                  One-piece belt frame for kettle
                </BodyStyled>
                <BodyStyled
                  gutterBottom
                  onClick={() => {
                    navigate("/" + paths.specialKettle);
                    scrollToTop(pageTopRef);
                  }}
                >
                  Special working condition design
                </BodyStyled>
                <BodyStyled
                  onClick={() => {
                    navigate("/" + paths.mechanicalSeal);
                    scrollToTop(pageTopRef);
                  }}
                >
                  Mechanical seal auxiliary system
                </BodyStyled>
              </Box>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={windowSize >= 960 ? 2 : 12}>
            <Box style={{ paddingBottom: "1rem" }}>
              <HeaderStyled
                onClick={() => {
                  navigate("/" + paths.applications);
                  scrollToTop(pageTopRef);
                }}
              >
                APPLICATIONS
              </HeaderStyled>
            </Box>
          </Grid>
          <Grid item xs={windowSize >= 960 ? 2 : 12}>
            <Box style={{ paddingBottom: "1rem" }}>
              <HeaderStyled
                onClick={() => {
                  navigate("/" + paths.contactUs);
                  scrollToTop(pageTopRef);
                }}
              >
                CONTACTS
              </HeaderStyled>
            </Box>
          </Grid>
          {/* <Grid item xs={3}>
            <Box display="flex" justifyContent="space-around">
              <QrImg1 />
              <QrImg2 />
            </Box>
          </Grid> */}
        </Grid>
        <Box
          display="flex"
          style={{ marginTop: windowSize >= 960 ? "30px" : "20px" }}
        >
          <span style={{ marginRight: "5px", marginTop: "1px" }}>
            <MailOutline style={{ color: "#c2c2c2" }} fontSize="small" />
          </span>
          <Mailto
            email="sales@fineseal.com.my"
            subject=""
            body=""
            color="#c2c2c2"
            style={{ fontSize: "14px" }}
          >
            sales@fineseal.com.my
          </Mailto>
        </Box>
      </CustomContainer>
      <Divider style={{ backgroundColor: "#555555" }} />
      <CustomContainer className="FooterBottom">
        <BodyStyled className="small">
          Copyright © Fineseal Engineering & Services Sdn Bhd
          (202301024500(1518423-D)). All rights reserved.
        </BodyStyled>
      </CustomContainer>
    </OutBox>
  );
};

export default Footer;
