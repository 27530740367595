import { List, ListItem, ListItemButton, Typography } from "@mui/material";
import React from "react";
import menuItems from "../Header/menuItems";
import { useNavigate } from "react-router-dom";

interface ShowDrawerProps {
  drawerHandler: any;
}

const ShowDrawer: React.FC<ShowDrawerProps> = ({ drawerHandler }) => {
  const navigate = useNavigate();

  return (
    <List>
      {menuItems.map((item, index) => (
        <ListItem key={index}>
          <ListItemButton
            onClick={() => {
              navigate(item.route);
              drawerHandler();
            }}
          >
            <Typography style={{ fontSize: "15px" }}>{item.name}</Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default ShowDrawer;
