import {
  Accordion,
  AccordionSummary,
  CardActionArea,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { themeColor } from "../../styles/Theme";

const ProductCenterDiv = styled.div`
  background-color: ${themeColor.blue};
  padding: 1.25rem;
`;

const TextStyled = styled(Typography)`
  &.header {
    color: white;
    font-weight: 600;
    font-size: 22px;

    @media (min-width: 960px) {
      font-size: 24px;
    }
  }

  &.subHeader {
    color: white;
    font-size: 16px;

    @media (min-width: 1231px) {
      font-size: 18px;
    }
  }

  &.productDes {
    color: ${themeColor.blue};
    font-weight: 600;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid ${themeColor.blue};
    font-size: 18px;

    @media (min-width: 960px) {
      font-size: 22px;
    }

    @media (min-width: 1121px) {
      font-size: 27px;
    }
  }

  &.cardContent {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;

    @media (min-width: 600px) {
      font-size: 16px;
    }
  }

  &.menuContent {
    font-size: 14px;
    @media (min-width: 600px) {
      font-size: 16px;
    }
  }
`;

const AccStyled = styled(Accordion)`
  &.MuiAccordion-root {
    background-color: ${themeColor.lightGrey};
    padding-bottom: 0.3rem;
  }

  &:before {
    display: none;
  }
`;

const AccSumStyled = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    padding: 0.5rem 1.25rem 0.5rem 0rem;
    background-color: ${themeColor.white};

    &:hover {
      color: ${themeColor.blue};
    }
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }

  & .MuiAccordionSummary-content {
    padding-left: 1.25rem;
    padding-right: 0.5rem;
    border-left: 0.25rem solid ${themeColor.blue};
  }
`;

const ImgDiv = styled.div`
  img {
    width: 100%;
    height: 100%;
  }

  &.productDes {
    margin: 20px 0;

    @media (min-width: 750px) {
      margin: 30px 0;
    }
  }
`;

const CardActionAreaStyled = styled(CardActionArea)`
  &.MuiCardActionArea-root {
    &:hover {
      color: ${themeColor.lightBlue};
    }
  }
`;
const DesContainer = styled.div`
  padding: 10px 10px;
  background-color: white;

  @media (min-width: 750px) {
    padding: 20px 30px;
  }

  @media (min-width: 960px) {
    padding: 40px 50px;
  }

  @media (min-width: 1231px) {
    padding: 50px 60px;
  }
`;

const DesTitleContainer = styled.div`
  // padding-top: 0.5rem;
  padding-bottom: 0.75rem;
`;

export {
  ProductCenterDiv,
  TextStyled,
  AccStyled,
  AccSumStyled,
  ImgDiv,
  CardActionAreaStyled,
  DesContainer,
  DesTitleContainer,
};
