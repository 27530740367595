export const paths = {
  home: "home",
  notFound: "not-found",
  aboutUs: "about-us",
  products: "products",
  conventionalKettle: "products/conventional",
  seal1: "products/conventional/seal1",
  seal2: "products/conventional/seal2",
  seal3: "products/conventional/seal3",
  seal4: "products/conventional/sea4",
  seal5: "products/conventional/seal5",
  seal6: "products/conventional/seal6",
  seal7: "products/conventional/seal7",
  seal8: "products/conventional/seal8",
  modularKettle: "products/modular",
  seal9: "products/modular/seal9",
  seal10: "products/modular/seal10",
  seal11: "products/modular/seal11",
  seal12: "products/modular/seal12",
  seal13: "products/modular/seal13",
  seal14: "products/modular/seal14",
  onePieceKettle: "products/onePiece",
  seal15: "products/onePiece/seal15",
  specialKettle: "products/special",
  seal16: "products/special/seal16",
  mechanicalSeal: "products/mechanical",
  seal17: "products/mechanical/seal17",
  seal18: "products/mechanical/seal18",
  seal19: "products/mechanical/seal19",
  applications: "applications",
  contactUs: "contact-us",
};

export const allowSearchParamsPaths: string[] = [];
