import React, { useEffect, useRef, useState } from "react";
import {
  AboutUsImg,
  ContactUsImg,
  QrImg1,
  QrImg2,
} from "../../components/Image";
import CustomContainer from "../../components/CustomContainer";
import { BoxStyled, TextStyled } from "../ContactUs/styles";
import { Grid, Stack } from "@mui/material";
import Footer from "../../components/Footer";
import { Mailto, SEO } from "../../services/hooks";
import Header from "../../components/Header";
import { ImgDiv } from "../Applications/styles";
import { HeaderImgDiv } from "../../utilities/common";

const ContactUs = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const pageTopRef = useRef(null);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <div>
      <SEO title="Contacts" description="Offline locations, Media contacts" />
      <div style={{ zIndex: 100 }} ref={pageTopRef}>
        <Header />
      </div>
      <div
        style={{ position: "relative", width: "100%", background: "#f0f0ef" }}
      >
        <HeaderImgDiv>
          <ContactUsImg />
        </HeaderImgDiv>
      </div>
      <div
        style={{ width: "100%", background: "#f0f0ef", position: "relative" }}
      >
        <CustomContainer className="ContactUsGrey">
          <BoxStyled className="header">
            <Stack direction={windowSize >= 960 ? "row" : "column"} spacing={1}>
              <TextStyled className="header">
                Fineseal Engineering & Services Sdn Bhd
              </TextStyled>
              <TextStyled className="subHeader">
                202301024500(1518423-D)
              </TextStyled>
            </Stack>
          </BoxStyled>
          {/* <TextStyled variant="h4" className="header">
          Office Locations
        </TextStyled> */}
          <BoxStyled className="body">
            <Grid container>
              <Grid item xs={windowSize >= 960 ? 3 : 12}>
                <TextStyled className="headerDes">SEA Office</TextStyled>
              </Grid>
              <Grid item xs={windowSize >= 960 ? 9 : 12}>
                <TextStyled className="body">
                  91C, Jalan Harmonium 35/1, Tmn Desa Tebrau, 81100 JB, Johor,
                  Malaysia
                </TextStyled>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "40px" }}>
              <Grid item xs={windowSize >= 960 ? 3 : 12}>
                <TextStyled className="headerDes">China Office</TextStyled>
              </Grid>
              <Grid item xs={windowSize >= 960 ? 9 : 12}>
                <TextStyled className="body">Kunshan, Wuxi</TextStyled>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "40px" }}>
              <Grid item xs={windowSize >= 960 ? 3 : 12}>
                <TextStyled className="headerDes">Media Contacts</TextStyled>
              </Grid>
              <Grid item xs={windowSize >= 960 ? 9 : 12}>
                <Mailto
                  email="sales@fineseal.com.my"
                  subject=""
                  body=""
                  variant="h6"
                  color="#555555"
                >
                  sales@fineseal.com.my
                </Mailto>
                <Stack
                  direction="row"
                  spacing={3}
                  style={{ marginTop: windowSize >= 960 ? "2rem" : "1.25rem" }}
                >
                  <ImgDiv>
                    <QrImg1 />
                  </ImgDiv>
                  <ImgDiv>
                    <QrImg2 />
                  </ImgDiv>
                </Stack>
              </Grid>
            </Grid>
          </BoxStyled>
          {/* <Divider />
        <TextStyled
          variant="body2"
          className="subBody"
          style={{ marginTop: "0.5rem" }}
        >
          sales@fineseal.com.my
        </TextStyled>
        <TextStyled
          variant="body2"
          className="subBody"
          style={{ marginBottom: "1rem" }}
        >
          www.fineseal.com.my
        </TextStyled>
        <Grid container spacing={1}>
          <Grid item>
            <QrImg1 />
          </Grid>
          <Grid item>
            <QrImg2 />
          </Grid>
        </Grid> */}
        </CustomContainer>
      </div>
      <div
        style={{
          position: "relative",
          width: "100%",
          background: "#f0f0ef",
        }}
      >
        <HeaderImgDiv>
          <AboutUsImg />
        </HeaderImgDiv>
      </div>
      <Footer pageTopRef={pageTopRef} />
    </div>
  );
};

export default ContactUs;
