import React from "react";
import { ButtonStyled } from "./styles";

interface CustomButtonProps {
  title: string;
  onClick?: React.MouseEventHandler;
}

const CustomButton: React.FC<CustomButtonProps> = ({ title, onClick }) => {
  return (
    <ButtonStyled variant="contained" onClick={onClick}>
      {title}
    </ButtonStyled>
  );
};

export default CustomButton;
