import React from "react";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import { paths } from "../../configs/routes";
import { getRelativePath } from "./common";
import AppRedirector from "./AppRedirector";
import Home from "../../pages/Home";
import Layout from "../../components/Layout";
import AboutUs from "../../pages/AboutUs";
import Applications from "../../pages/Applications";
import ContactUs from "../../pages/ContactUs";
import Products from "../../pages/Products";
import ProductDetails from "../../pages/Products/productDetails";

const AppRouter = () => {
  globalThis["a"] = useNavigate();

  const location = useLocation();
  const pathName = location.pathname; // this is to get the current pathname
  // console.log("pathanme", location, "=", location.pathname)
  const relativePath = getRelativePath(pathName); // need to check on here!!!

  return (
    <AppRedirector>
      <Layout currentPath={relativePath}>
        <Routes>
          <Route path={paths.home} element={<Home />} />
          <Route path={paths.aboutUs} element={<AboutUs />} />
          {/******************* All Products *******************/}
          <Route path={paths.products} element={<Products expanded={"0"} />} />
          {/******************* Conventional Kettles *******************/}
          <Route
            path={paths.conventionalKettle}
            element={<Products expanded={"1"} />}
          />
          <Route
            path={paths.seal1}
            element={<ProductDetails id={0} category="conventional" />}
          />
          <Route
            path={paths.seal2}
            element={<ProductDetails id={1} category="conventional" />}
          />
          <Route
            path={paths.seal3}
            element={<ProductDetails id={2} category="conventional" />}
          />
          <Route
            path={paths.seal4}
            element={<ProductDetails id={3} category="conventional" />}
          />
          <Route
            path={paths.seal5}
            element={<ProductDetails id={4} category="conventional" />}
          />
          <Route
            path={paths.seal6}
            element={<ProductDetails id={5} category="conventional" />}
          />
          <Route
            path={paths.seal7}
            element={<ProductDetails id={6} category="conventional" />}
          />
          <Route
            path={paths.seal8}
            element={<ProductDetails id={7} category="conventional" />}
          />
          {/******************* Modular Kettles *******************/}
          <Route
            path={paths.modularKettle}
            element={<Products expanded={"2"} />}
          />
          <Route
            path={paths.seal9}
            element={<ProductDetails id={0} category="modular" />}
          />
          <Route
            path={paths.seal10}
            element={<ProductDetails id={1} category="modular" />}
          />
          <Route
            path={paths.seal11}
            element={<ProductDetails id={2} category="modular" />}
          />
          <Route
            path={paths.seal12}
            element={<ProductDetails id={3} category="modular" />}
          />
          <Route
            path={paths.seal13}
            element={<ProductDetails id={4} category="modular" />}
          />
          <Route
            path={paths.seal14}
            element={<ProductDetails id={5} category="modular" />}
          />
          {/******************* One Piece Kettles *******************/}
          <Route
            path={paths.onePieceKettle}
            element={<Products expanded={"3"} />}
          />
          <Route
            path={paths.seal15}
            element={<ProductDetails id={0} category="onePiece" />}
          />
          {/******************* Special Kettles *******************/}
          <Route
            path={paths.specialKettle}
            element={<Products expanded={"4"} />}
          />
          <Route
            path={paths.seal16}
            element={<ProductDetails id={0} category="special" />}
          />
          k{/******************* Mechanical Kettles *******************/}
          <Route
            path={paths.mechanicalSeal}
            element={<Products expanded={"5"} />}
          />
          <Route
            path={paths.seal17}
            element={<ProductDetails id={0} category="mechanical" />}
          />
          <Route
            path={paths.seal18}
            element={<ProductDetails id={1} category="mechanical" />}
          />
          <Route
            path={paths.seal19}
            element={<ProductDetails id={2} category="mechanical" />}
          />
          <Route path={paths.applications} element={<Applications />} />
          <Route path={paths.contactUs} element={<ContactUs />} />
        </Routes>
      </Layout>
    </AppRedirector>
  );
};

export default AppRouter;
