import React from "react";
import { AppBody, AppContainer, Main } from "./styles";

interface LayoutProps {
  children: React.ReactNode;
  currentPath?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, currentPath }) => {
  return (
    <AppContainer>
      <Main>
        <AppBody>{children}</AppBody>
      </Main>
    </AppContainer>
  );
};

export default Layout;
