import React from "react";
import { paths } from "../../configs/routes";

export interface productMenuItem {
  id: string;
  name: string;
  route: string;
}

const productMenuItems: productMenuItem[] = [
  {
    id: "0",
    name: "All",
    route: "/" + paths.products,
  },
  {
    id: "1",
    name: "For conventional kettle",
    route: "/" + paths.conventionalKettle,
  },
  {
    id: "2",
    name: "For modular kettle",
    route: "/" + paths.modularKettle,
  },
  {
    id: "3",
    name: "One-piece belt frame for kettle",
    route: "/" + paths.onePieceKettle,
  },
  {
    id: "4",
    name: "Special working condition design",
    route: "/" + paths.specialKettle,
  },
  {
    id: "5",
    name: "Mechanical seal auxiliary system",
    route: "/" + paths.mechanicalSeal,
  },
];

export default productMenuItems;
