import { Card, CardContent, CardMedia, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CardActionAreaStyled, ImgDiv, TextStyled } from "./styles";
import CustomPagination from "../../components/CustomPagination";
import { productItem } from "./productList";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../services/hooks";

interface ShowProductsProps {
  category: productItem[];
  uniqueKey: string;
  pageRef: any;
  pageTopRef: any;
}

const ShowProducts: React.FC<ShowProductsProps> = ({
  category,
  uniqueKey,
  pageRef,
  pageTopRef
}) => {
  const [pageProducts, setPageProducts] = useState<productItem[]>([]);

  const navigate = useNavigate();

  const handleLink = (path: string) => {
    navigate(path);
  };

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const pageSize =
    windowSize >= 1121
      ? 12
      : windowSize >= 960 && windowSize < 1121
      ? 8
      : windowSize >= 750 && windowSize < 960
      ? 9
      : 8;

  return (
    <>
      <Grid container spacing={windowSize >= 960 ? 2 : 1}>
        {pageProducts.map((product, index) => (
          <Grid
            item
            xs={
              windowSize >= 1121
                ? 4
                : windowSize >= 960 && windowSize < 1121
                ? 6
                : windowSize >= 750 && windowSize < 960
                ? 4
                : 6
            }
            key={index}
          >
            <Card>
              <CardActionAreaStyled
                onClick={() => {
                  handleLink(product.route);
                  scrollToTop(pageTopRef);
                }}
              >
                <CardMedia>
                  <ImgDiv>{product.image}</ImgDiv>
                </CardMedia>
                <CardContent
                  style={{
                    textAlign: "center",
                    paddingTop: "0",
                    paddingLeft: windowSize < 600 ? "14px" : "16px",
                    paddingRight: windowSize < 600 ? "14px" : "16px",
                  }}
                >
                  <TextStyled className="cardContent">
                    {product.name}
                  </TextStyled>
                </CardContent>
              </CardActionAreaStyled>
            </Card>
          </Grid>
        ))}
      </Grid>
      <CustomPagination
        key={uniqueKey}
        uniqueKey={uniqueKey}
        category={category}
        pageSize={pageSize}
        pageRef={pageRef}
        setPageProducts={(p) => setPageProducts(p)}
      />
    </>
  );
};

export default ShowProducts;
