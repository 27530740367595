import React from "react";
import {
  SealImg1,
  SealImg10,
  SealImg11,
  SealImg12,
  SealImg13,
  SealImg14,
  SealImg15,
  SealImg16,
  SealImg17,
  SealImg18,
  SealImg19,
  SealImg2,
  SealImg3,
  SealImg4,
  SealImg5,
  SealImg6,
  SealImg7,
  SealImg8,
  SealImg9,
  SealImgDes1,
  SealImgDes10,
  SealImgDes11,
  SealImgDes12,
  SealImgDes13,
  SealImgDes14,
  SealImgDes15,
  SealImgDes16,
  SealImgDes17,
  SealImgDes18,
  SealImgDes19,
  SealImgDes2,
  SealImgDes3,
  SealImgDes4,
  SealImgDes5,
  SealImgDes6,
  SealImgDes7,
  SealImgDes8,
  SealImgDes9,
} from "../../components/Image";
import { paths } from "../../configs/routes";

export interface productItem {
  id?: string;
  name: string;
  shortName?: string;
  route: string;
  image?: JSX.Element;
  imageDes?: JSX.Element;
  category?: string;
}

const conventionalKettles: productItem[] = [
  {
    id: "0",
    name: "M204U mechanical seal",
    category: "For conventional kettle",
    image: <SealImg1 />,
    imageDes: <SealImgDes1 />,
    route: "/" + paths.seal1,
  },
  {
    id: "1",
    name: "M212U mechanical seal",
    category: "For conventional kettle",
    image: <SealImg2 />,
    imageDes: <SealImgDes2 />,
    route: "/" + paths.seal2,
  },
  {
    id: "2",
    name: "2020Y 2021Y special mechanical seal for rake drying",
    shortName: "2020Y 2021Y special mechan...",
    category: "For conventional kettle",
    image: <SealImg3 />,
    imageDes: <SealImgDes3 />,
    route: "/" + paths.seal3,
  },
  {
    id: "3",
    name: "M205U M206U M207U mechanical seal",
    shortName: "M205U M206U M207U mecha...",
    category: "For conventional kettle",
    image: <SealImg4 />,
    imageDes: <SealImgDes4 />,
    route: "/" + paths.seal4,
  },
  {
    id: "4",
    name: "2009U 2010U mechanical seal",
    category: "For conventional kettle",
    image: <SealImg5 />,
    imageDes: <SealImgDes5 />,
    route: "/" + paths.seal5,
  },
  {
    id: "5",
    name: "2004U 2005U mechanical seal",
    category: "For conventional kettle",
    image: <SealImg6 />,
    imageDes: <SealImgDes6 />,
    route: "/" + paths.seal6,
  },
  {
    id: "6",
    name: "2022U 2023U metal bellows mechanical seal",
    shortName: "2022U 2023U metal bellows...",
    category: "For conventional kettle",
    image: <SealImg7 />,
    imageDes: <SealImgDes7 />,
    route: "/" + paths.seal7,
  },
  {
    id: "7",
    name: "M208U mechanical seal",
    category: "For conventional kettle",
    image: <SealImg8 />,
    imageDes: <SealImgDes8 />,
    route: "/" + paths.seal8,
  },
];

const modularKettles: productItem[] = [
  {
    id: "0",
    name: "X212W, X212U mechanical seal",
    category: "For modular kettle",
    image: <SealImg9 />,
    imageDes: <SealImgDes9 />,
    route: "/" + paths.seal9,
  },
  {
    id: "1",
    name: "X204B mechanical seal",
    category: "For modular kettle",
    image: <SealImg10 />,
    imageDes: <SealImgDes10 />,
    route: "/" + paths.seal10,
  },
  {
    id: "2",
    name: "X201U mechanical seal",
    category: "For modular kettle",
    image: <SealImg11 />,
    imageDes: <SealImgDes11 />,
    route: "/" + paths.seal11,
  },
  {
    id: "3",
    name: "X203U mechanical seal",
    category: "For modular kettle",
    image: <SealImg12 />,
    imageDes: <SealImgDes12 />,
    route: "/" + paths.seal12,
  },
  {
    id: "4",
    name: "2020B type mechanical seal",
    category: "For modular kettle",
    image: <SealImg13 />,
    imageDes: <SealImgDes13 />,
    route: "/" + paths.seal13,
  },
  {
    id: "5",
    name: "2021B mechanical seal",
    category: "For modular kettle",
    image: <SealImg14 />,
    imageDes: <SealImgDes14 />,
    route: "/" + paths.seal14,
  },
];

const onePieceKettles: productItem[] = [
  {
    id: "0",
    name: "X400D type stirring transmission sealing device",
    shortName: "X400D type stirring transmis...",
    category: "One-piece belt frame for kettle",
    image: <SealImg15 />,
    imageDes: <SealImgDes15 />,
    route: "/" + paths.seal15,
  },
];

const specialKettles: productItem[] = [
  {
    id: "0",
    name: "PLAN53A system",
    category: "Special working condition design",
    image: <SealImg16 />,
    imageDes: <SealImgDes16 />,
    route: "/" + paths.seal16,
  },
];

const mechanicalSeals: productItem[] = [
  {
    id: "0",
    name: "XYM-Z630 type pressurized tank",
    shortName: "XYM-Z630 type pressurized t...",
    category: "Mechanical seal auxiliary system",
    image: <SealImg17 />,
    imageDes: <SealImgDes17 />,
    route: "/" + paths.seal17,
  },
  {
    id: "1",
    name: "XYM-C160 balance tank",
    category: "Mechanical seal auxiliary system",
    image: <SealImg18 />,
    imageDes: <SealImgDes18 />,
    route: "/" + paths.seal18,
  },
  {
    id: "2",
    name: "XYM-Z280 pressurized tank",
    category: "Mechanical seal auxiliary system",
    image: <SealImg19 />,
    imageDes: <SealImgDes19 />,
    route: "/" + paths.seal19,
  },
];

const productItems: productItem[] = [
  {
    id: "0",
    name: "X212W, X212U mechanical seal",
    category: "For modular kettle",
    image: <SealImg9 />,
    imageDes: <SealImgDes9 />,
    route: "/" + paths.seal9,
  },
  {
    id: "1",
    name: "X204B mechanical seal",
    category: "For modular kettle",
    image: <SealImg10 />,
    imageDes: <SealImgDes10 />,
    route: "/" + paths.seal10,
  },
  {
    id: "2",
    name: "X201U mechanical seal",
    category: "For modular kettle",
    image: <SealImg11 />,
    imageDes: <SealImgDes11 />,
    route: "/" + paths.seal11,
  },
  {
    id: "3",
    name: "X203U mechanical seal",
    category: "For modular kettle",
    image: <SealImg12 />,
    imageDes: <SealImgDes12 />,
    route: "/" + paths.seal12,
  },
  {
    id: "4",
    name: "M204U mechanical seal",
    category: "For conventional kettle",
    image: <SealImg1 />,
    imageDes: <SealImgDes1 />,
    route: "/" + paths.seal1,
  },
  {
    id: "5",
    name: "M212U mechanical seal",
    category: "For conventional kettle",
    image: <SealImg2 />,
    imageDes: <SealImgDes2 />,
    route: "/" + paths.seal2,
  },
  {
    id: "6",
    name: "2020B type mechanical seal",
    category: "For modular kettle",
    image: <SealImg13 />,
    imageDes: <SealImgDes13 />,
    route: "/" + paths.seal13,
  },
  {
    id: "7",
    name: "2021B mechanical seal",
    category: "For modular kettle",
    image: <SealImg14 />,
    imageDes: <SealImgDes14 />,
    route: "/" + paths.seal14,
  },
  {
    id: "8",
    name: "2020Y 2021Y special mechanical seal for rake drying",
    category: "For conventional kettle",
    shortName: "2020Y 2021Y special mechan...",
    image: <SealImg3 />,
    imageDes: <SealImgDes3 />,
    route: "/" + paths.seal3,
  },
  {
    id: "9",
    name: "M205U M206U M207U mechanical seal",
    category: "For conventional kettle",
    shortName: "M205U M206U M207U mecha...",
    image: <SealImg4 />,
    imageDes: <SealImgDes4 />,
    route: "/" + paths.seal4,
  },
  {
    id: "10",
    name: "2009U 2010U mechanical seal",
    category: "For conventional kettle",
    image: <SealImg5 />,
    imageDes: <SealImgDes5 />,
    route: "/" + paths.seal5,
  },
  {
    id: "11",
    name: "2004U 2005U mechanical seal",
    category: "For conventional kettle",
    image: <SealImg6 />,
    imageDes: <SealImgDes6 />,
    route: "/" + paths.seal6,
  },
  {
    id: "12",
    name: "2022U 2023U metal bellows mechanical seal",
    category: "For conventional kettle",
    shortName: "2022U 2023U metal bellows...",
    image: <SealImg7 />,
    imageDes: <SealImgDes7 />,
    route: "/" + paths.seal7,
  },
  {
    id: "13",
    name: "M208U mechanical seal",
    category: "For conventional kettle",
    image: <SealImg8 />,
    imageDes: <SealImgDes8 />,
    route: "/" + paths.seal8,
  },
  {
    id: "14",
    name: "X400D type stirring transmission sealing device",
    category: "One-piece belt frame for kettle",
    shortName: "X400D type stirring transmis...",
    image: <SealImg15 />,
    imageDes: <SealImgDes15 />,
    route: "/" + paths.seal15,
  },
  {
    id: "15",
    name: "XYM-Z630 type pressurized tank",
    category: "Mechanical seal auxiliary system",
    shortName: "XYM-Z630 type pressurized t...",
    image: <SealImg17 />,
    imageDes: <SealImgDes17 />,
    route: "/" + paths.seal17,
  },
  {
    id: "16",
    name: "XYM-C160 balance tank",
    category: "Mechanical seal auxiliary system",
    image: <SealImg18 />,
    imageDes: <SealImgDes18 />,
    route: "/" + paths.seal18,
  },
  {
    id: "17",
    name: "PLAN53A system",
    category: "Special working condition design",
    image: <SealImg16 />,
    imageDes: <SealImgDes16 />,
    route: "/" + paths.seal16,
  },
  {
    id: "18",
    name: "XYM-Z280 pressurized tank",
    category: "Mechanical seal auxiliary system",
    image: <SealImg19 />,
    imageDes: <SealImgDes19 />,
    route: "/" + paths.seal19,
  },
];

export {
  productItems,
  conventionalKettles,
  modularKettles,
  onePieceKettles,
  specialKettles,
  mechanicalSeals,
};
