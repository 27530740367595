import styled from "@emotion/styled";
import { themeColor } from "../../styles/Theme";

const ContainerStyled = styled.div`
  &.ProductCenter {
    max-width: 1230px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 30px;
    flex-direction: column;
    background-color: ${themeColor.lightWhiteGrey};

    @media (min-width: 675px) {
      padding: 50px 60px;
    }

    @media (min-width: 1024px) {
      padding: 70px 80px;
    }

    @media (min-width: 1345px) {
      padding: 70px 100px;
    }
  }

  &.HomeApp {
    max-width: 1230px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 30px;
    flex-direction: column;
    background-color: ${themeColor.lightWhiteGrey};

    @media (min-width: 613px) {
      padding: 50px 60px;
    }

    @media (min-width: 1024px) {
      padding: 70px 100px;
    }

    @media (min-width: 1345px) {
      padding: 70px 120px;
    }
  }

  &.AboutUs {
    padding: 20px 30px 40px 30px;
    max-width: 1230px;
    margin: 0 auto;
    background-color: ${themeColor.white};

    @media (min-width: 960px) {
      padding: 40px 50px 60px 50px;
    }

    @media (min-width: 1231px) {
      padding: 80px 100px;
    }
  }

  &.AboutUsGrey {
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 1.875rem;
    background-color: ${themeColor.lightGrey};

    @media (min-width: 960px) {
      padding: 0 3.125rem;
    }

    @media (min-width: 1231px) {
      padding: 0 6.25rem;
    }
  }

  &.AboutUsWhite {
    max-width: 1230px;
    margin: 0 auto;
    padding: 3rem 2.5rem;

    @media (min-width: 675px) {
      padding: 4rem 5rem;
    }

    @media (min-width: 960px) {
      padding: 5rem 10rem;
    }

    @media (min-width: 1121px) {
      padding: 5rem 15rem;
    }
  }

  &.ContactUsGrey {
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 30px;
    background-color: ${themeColor.lightGrey};

    @media (min-width: 960px) {
      padding: 0 50px;
    }

    @media (min-width: 1231px) {
      padding: 0 100px;
    }

    @media (min-width: 1366px) {
      padding: 0 200px;
    }
  }

  &.App {
    padding: 2.5rem 1.875rem;
    background-color: ${themeColor.lightGrey};
    max-width: 1345px;
    margin: 0 auto;

    @media (min-width: 960px) {
      padding: 3.75rem 3.125rem;
    }

    @media (min-width: 1231px) {
      padding: 5rem 6.25rem;
    }

    @media (min-width: 1500px) {
      padding: 5rem 0;
    }
  }

  &.Footer {
    background-color: #171b1d;
    max-width: 1230px;
    margin: 0 auto;
    color: white;
    padding: 40px 30px 5px 30px;

    @media (min-width: 960px) {
      padding: 40px 50px 5px 50px;
    }

    @media (min-width: 1231px) {
      padding: 40px 80px 5px 80px;
    }

    @media (min-width: 1345px) {
      padding: 40px 100px 5px 100px;
    }
  }

  &.FooterBottom {
    background-color: #171b1d;
    color: white;
    max-width: 1230px;
    margin: 0 auto;
    color: white;
    padding: 5px 30px 40px 30px;

    @media (min-width: 960px) {
      padding: 5px 50px 40px 50px;
    }

    @media (min-width: 1231px) {
      padding: 5px 80px 40px 80px;
    }

    @media (min-width: 1345px) {
      padding: 5px 100px 40 100px;
    }
  }

  &.ProductPage {
    max-width: 1230px;
    margin: 0 auto;
    padding: 2.5rem 1rem;
    background-color: ${themeColor.lightGrey};

    @media (min-width: 750px) {
      padding: 2.5rem 1.875rem;
    }

    @media (min-width: 960px) {
      padding: 3.75rem 2.5rem;
    }

    @media (min-width: 1231px) {
      padding: 3.75rem 4rem;
    }
  }

  &.ProductDetails {
    max-width: 1230px;
    margin: 0 auto;
    padding: 20px 10px;
    background-color: ${themeColor.lightGrey};

    @media (min-width: 750px) {
      padding: 40px 30px;
    }

    @media (min-width: 960px) {
      padding: 40px 40px;
    }

    @media (min-width: 1121px) {
      padding: 60px 50px;
    }

    @media (min-width: 1231px) {
      padding: 60px 100px;
    }
  }
`;

export { ContainerStyled };
