import React from "react";
import {
  ArrowStyled,
  CardActionAreaStyled,
  CardStyled,
  ImgDiv,
  TextDiv,
  TextStyled,
} from "./styles";
import { Card, CardContent, CardMedia, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../services/hooks";

interface CustomCardProps {
  title: string;
  description?: string;
  isProduct?: boolean;
  image: JSX.Element;
  route?: string;
  pageTopRef?: any;
}

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  description,
  isProduct,
  image,
  route,
  pageTopRef,
}) => {
  const navigate = useNavigate();

  return (
    <>
      {isProduct ? (
        <Card style={{ borderRadius: 0 }}>
          <CardActionAreaStyled
            onClick={() => {
              navigate("/" + route);
              scrollToTop(pageTopRef);
            }}
          >
            <Grid
              container
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid item xs={7}>
                <CardContent
                  style={{
                    paddingLeft: "24px",
                    paddingRight: "0",
                    paddingBottom: "16px",
                  }}
                >
                  <TextStyled className="prodHeader" gutterBottom>
                    {title}
                  </TextStyled>
                  <TextStyled className="prodBody">{description}</TextStyled>
                  <ArrowStyled fontSize="medium" />
                </CardContent>
              </Grid>
              <Grid item xs={5}>
                <CardMedia>
                  <ImgDiv>{image}</ImgDiv>
                </CardMedia>
              </Grid>
            </Grid>
          </CardActionAreaStyled>
        </Card>
      ) : (
        <CardStyled>
          <div style={{ position: "relative" }}>
            <CardMedia>
              <ImgDiv>{image}</ImgDiv>
            </CardMedia>
            <TextDiv className="app">
              <TextStyled className="appBody">{title}</TextStyled>
              {/* <AddCircleOutline fontSize="medium" /> */}
            </TextDiv>
          </div>
        </CardStyled>
      )}
    </>
  );
};

export default CustomCard;
