import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { allowSearchParamsPaths } from "../../configs/routes";
import { paths } from "../../configs/routes";
// import Obj from "utilities/common";

type RedirectProps = {
  to: string;
};

const Redirect: React.FC<RedirectProps> = ({ to }) => {
  let navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
};

const baseRouterPath = ((path) => (path ? `/${path}` : ""))(
  process.env.REACT_APP_BASE_PATH?.replace("/", "").trim()
);

const isValidPath = (currentPath: string) =>
  Object.values(paths).includes(currentPath);

// now in our code dont need to have base Router path, just straight /pathname
const getRelativePath = (currentPath: string) =>
  currentPath.replace(`/`, "").trim();

const navigate = (route: string) => (
  // <Redirect
  //   to={`${baseRouterPath}/${route.replace("/", "").trim()}`}
  // />
  <Redirect to={`/${route.replace("/", "").trim()}`} />
);

const allowSearchParams = (currentPath: string) =>
  allowSearchParamsPaths.includes(currentPath);

export {
  baseRouterPath,
  getRelativePath,
  isValidPath,
  navigate,
  allowSearchParams,
};
