import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { themeColor } from "../../styles/Theme";

const TextStyled = styled(Typography)`
  &.header {
    color: ${themeColor.blue};
    font-size: 25px;

    @media (min-width: 960px) {
      font-size: 30px;
    }

    @media (min-width: 1121px) {
      font-size: 35px;
    }
  }

  &.subHeader {
    color: ${themeColor.lightBlack};
    font-size: 15px;

    @media (min-width: 960px) {
      margin-top: 1rem;
    }

    @media (min-width: 1121px) {
      font-size: 20px;
    }
  }

  &.headerDes {
    color: ${themeColor.blue};
    font-size: 22px;
    margin-bottom: 10px;

    @media (min-width: 960px) {
      margin-bottom: 0;
    }

    @media (min-width: 1121px) {
      font-size: 27px;
    }
  }

  &.body {
    color: ${themeColor.lightBlack};
    font-size: 18px;
  }

  &.subBody {
    color: black;
  }
`;

const LineStyled = styled.div`
  height: 0.2rem;
  width: 3rem;
  background-color: ${themeColor.blue};
  margin-bottom: 2.5rem;
`;

const BoxStyled = styled(Box)`
  &.header {
    padding-top: 35px;
    padding-bottom: 40px;

    @media (min-width: 1121px) {
      padding-top: 65px;
    }

    @media (min-width: 1366px) {
      padding-top: 80px;
    }
  }

  &.body {
    padding-bottom: 35px;

    @media (min-width: 1121px) {
      padding-bottom: 65px;
    }

    @media (min-width: 1366px) {
      padding-bottom: 80px;
    }
  }
`;

const ImgDiv = styled.div`
  img {
    width: 100%;
    heigth: 100%;
  }
`;

export { TextStyled, LineStyled, BoxStyled, ImgDiv };
