import React from "react";
import { LineIcon, SubTitleStyled, TitleStyled } from "./styles";
import { HorizontalRule } from "@mui/icons-material";
import { Stack } from "@mui/material";

interface CustomTitleProps {
  mainTitle: string;
  subTitle: string;
}

const CustomTitle: React.FC<CustomTitleProps> = ({ mainTitle, subTitle }) => {
  return (
    <Stack
      style={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <TitleStyled>{mainTitle}</TitleStyled>
      <LineIcon>
        <HorizontalRule fontSize="large" />
      </LineIcon>
      <SubTitleStyled>{subTitle}</SubTitleStyled>
    </Stack>
  );
};

export default CustomTitle;
