import React from "react";
import { paths } from "../../configs/routes";

export interface menuItem {
  navId?: string;
  name: string;
  route: string;
}

const menuItems: menuItem[] = [
  {
    navId: "0",
    name: "HOME",
    route: "/" + paths.home,
  },
  {
    navId: "1",
    name: "ABOUT US",
    route: "/" + paths.aboutUs,
  },
  {
    navId: "2",
    name: "PRODUCTS",
    route: "/" + paths.products,
  },
  {
    navId: "3",
    name: "APPLICATIONS",
    route: "/" + paths.applications,
  },
  {
    navId: "4",
    name: "CONTACT US",
    route: "/" + paths.contactUs,
  },
];

export default menuItems;
