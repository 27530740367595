import { Grid } from "@mui/material";
import styled from "styled-components";

const ImgDiv = styled.div`
  img {
    width: 100%;
    height: 100%;
  }
`;

export { ImgDiv };
