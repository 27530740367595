import { Breadcrumbs, Link } from "@mui/material";
import styled from "@emotion/styled";
import { themeColor } from "../../styles/Theme";

const BreadcrumbStyled = styled(Breadcrumbs)`
  padding-bottom: 10px;

  @media (min-width: 750px) {
    padding-bottom: 20px;
  }
`;

const LinkStyled = styled(Link)`
  && {
    cursor: pointer;
    font-size: 14px;

    @media (min-width: 960px) {
      font-size: 16px;
    }

    &:hover {
      color: ${themeColor.lightBlue};
    }
  }
`;

export { BreadcrumbStyled, LinkStyled };
