import styled from "@emotion/styled";
import { themeColor } from "../../styles/Theme";
import { Grid, Typography } from "@mui/material";

const AboutUsStyled = styled(Typography)`
  &.header {
    // padding-top: 1.25rem;
    line-height: 1.25;
    color: ${themeColor.grey};
    font-weight: 600;
    font-size: 35px;

    @media (min-width: 960px) {
      font-size: 40px;
    }

    @media (min-width: 1121px) {
      font-size: 45px;
    }
  }

  &.body {
    padding-top: 20px;
    padding-bottom: 60px;
    color: ${themeColor.lightBlack};
    line-height: 2;
    font-size: 16px;

    @media (min-width: 960px) {
      font-size: 18px;
      padding-top: 40px;
    }
  }

  &.subHeader {
    padding-bottom: 1.25rem;
    color: #333;
    font-weight: 600;
    border-bottom: 2px solid ${themeColor.grey};
  }

  &.subBody {
    padding-top: 1.25rem;
    padding-bottom: 40px;
    color: #999999;
    font-size: 16px;

    @media (min-width: 960px) {
      font-size: 18px;
    }
  }
`;

const GridContainer = styled(Grid)`
  margin: 20px 0;

  @media (min-width: 1024px) {
    margin: 40px 0;
  }
`;

const AppGrid = styled(Grid)`
  &.bot {
    margin-bottom: 40px;

    @media (min-width: 1024px) {
      margin-bottom: 60px;
    }
  }

  &.top {
    margin-top: 40px;

    @media (min-width: 1024px) {
      margin-top: 60px;
    }
  }
`;

export { AboutUsStyled, GridContainer, AppGrid };
