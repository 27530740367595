import { Breakpoint } from "@mui/material/";
import { keyframes } from "@emotion/react";
import { theme } from "../styles/Theme";
import styled from "@emotion/styled";

const getBreakPointWidth = (breakpoint: Breakpoint) => {
  const pointWidth = theme.breakpoints.values[breakpoint];
  return pointWidth + "px";
};

const scaleUp = keyframes`
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
`;

const HeaderImgDiv = styled.div`
  max-width: 1345px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
  }
`;

export { getBreakPointWidth, scaleUp, HeaderImgDiv };
