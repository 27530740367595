import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";

const HeaderStyled = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: bold;
    // line-height: 55px;
    cursor: pointer;
  }
`;

const BodyStyled = styled(Typography)`
  && {
    color: #c2c2c2;
    font-size: 14px;
    cursor: pointer;

    &.small {
      font-size: 12px;
      color: #818284;
      cursor: text;
    }
  }
`;

const OutBox = styled(Box)`
  && {
    position: static;
    display: inline-block;
    width: 100%;
    background-color: #171b1d;
  }
`;

export { HeaderStyled, BodyStyled, OutBox };
