import React, { useRef } from "react";
import { DesContainer, DesTitleContainer, ImgDiv, TextStyled } from "./styles";
import {
  conventionalKettles,
  mechanicalSeals,
  modularKettles,
  onePieceKettles,
  specialKettles,
} from "./productList";
import CustomContainer from "../../components/CustomContainer";
import { Divider, Stack } from "@mui/material";
import Footer from "../../components/Footer";
import { paths } from "../../configs/routes";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import Header from "../../components/Header";

interface ProductDetailsProps {
  id: number;
  category: string;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ id, category }) => {
  const pageTopRef = useRef(null);

  const products =
    category === "conventional"
      ? conventionalKettles
      : category === "modular"
      ? modularKettles
      : category === "onePiece"
      ? onePieceKettles
      : category === "special"
      ? specialKettles
      : mechanicalSeals;

  const prevRoute =
    category === "conventional"
      ? "/" + paths.conventionalKettle
      : category === "modular"
      ? "/" + paths.modularKettle
      : category === "onePiece"
      ? "/" + paths.onePieceKettle
      : category === "special"
      ? "/" + paths.specialKettle
      : "/" + paths.mechanicalSeal;

  return (
    <Stack>
      <div style={{ zIndex: 100 }} ref={pageTopRef}>
        <Header />
      </div>
      <div
        style={{ width: "100%", background: "#f0f0ef", position: "relative" }}
      >
        <CustomContainer className="ProductDetails">
          <CustomBreadcrumb
            item={products[id]}
            prevRoute={prevRoute}
            pageTopRef={pageTopRef}
          />
          <DesContainer>
            <DesTitleContainer>
              <TextStyled
                className="productDes"
                variant="body1"
                variantMapping={{ body1: "span" }}
              >
                Description
              </TextStyled>
            </DesTitleContainer>
            <Divider />
            <ImgDiv className="productDes">{products[id].imageDes}</ImgDiv>
          </DesContainer>
        </CustomContainer>
      </div>
      <Footer pageTopRef={pageTopRef} />
    </Stack>
  );
};

export default ProductDetails;
