import React from "react";
import { ContainerStyled } from "./styles";

interface CustomContainerProps {
  children: React.ReactNode;
  className: string;
}

const CustomContainer: React.FC<CustomContainerProps> = ({
  children,
  className,
}) => {
  return (
    <ContainerStyled className={className}>
      {children}
    </ContainerStyled>
  );
};

export default CustomContainer;
