import React, { useEffect, useRef, useState } from "react";
import { SiliconBgImg } from "../../components/Image";
import { BoxStyled, LineStyled, TextStyled } from "./styles";
import CustomContainer from "../../components/CustomContainer";
import { Grid } from "@mui/material";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { SEO } from "../../services/hooks";
import { HeaderImgDiv } from "../../utilities/common";

const AboutUs = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const pageTopRef = useRef(null);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <div>
      <SEO
        title="About Us"
        description="Fineseal Engineering is a specialized corporation that focuses on
              the design, R&D, manufacturing, sales, and marketing of fluid
              mechanical seals. Our expertise lies in providing comprehensive
              solutions to common challenges such as leakage, fast wear-off, and
              dripping, all made possible by our patented technology, modular
              design, and versatile production capabilities. We are
              well-equipped to meet a wide range of material and application
              requirements."
      />
      <div style={{ zIndex: 100 }} ref={pageTopRef}>
        <Header />
      </div>
      <div style={{ position: "relative", width: "100%" }}>
        <HeaderImgDiv>
          <SiliconBgImg />
        </HeaderImgDiv>
      </div>
      <div
        style={{ width: "100%", background: "#f0f0ef", position: "relative" }}
      >
        <CustomContainer className="AboutUsGrey">
          <BoxStyled className="header">
            <TextStyled className="header">FINESEAL ENGINEERING</TextStyled>
            <LineStyled className="headerLine" />
            <TextStyled className="headerDes">
              Delivering fluid mechanical seal solutions
            </TextStyled>
          </BoxStyled>
          <BoxStyled className="body">
            <TextStyled className="body">
              Fineseal Engineering is a specialized corporation that focuses on
              the design, R&D, manufacturing, sales, and marketing of fluid
              mechanical seals. Our expertise lies in providing comprehensive
              solutions to common challenges such as leakage, fast wear-off, and
              dripping, all made possible by our patented technology, modular
              design, and versatile production capabilities. We are
              well-equipped to meet a wide range of material and application
              requirements.
            </TextStyled>
            <TextStyled className="body">
              Our product applications span across diverse industries, including
              chemical, pharmaceutical, metallurgical, food, and petrochemical
              sectors. We are confident that Fineseal Engineering is the prudent
              choice for all your mechanical seal requirements.
            </TextStyled>
            <TextStyled className="body" style={{ paddingBottom: 0 }}>
              Our innovative technology is exemplified by the following patent
              references:
            </TextStyled>
            <TextStyled className="body" style={{ paddingBottom: 0 }}>
              ZL202022563587.0, ZL202022562733.8, ZL20212091194.0,
              ZL202120291183.2, ZL20222840809.8
            </TextStyled>
          </BoxStyled>
        </CustomContainer>
      </div>
      <div style={{ width: "100%", position: "relative" }}>
        <CustomContainer className="AboutUsWhite">
          {/* <AboutUsImg2 /> */}
          <Grid container spacing={windowSize >= 675 ? 10 : 4}>
            <Grid item xs={windowSize >= 675 ? 4 : 12}>
              <TextStyled className="subHeader">Precision</TextStyled>
              {windowSize < 675 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <LineStyled className="subHeaderLine" />
                </div>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={windowSize >= 675 ? 4 : 12}>
              <TextStyled className="subHeader">Reliability</TextStyled>
              {windowSize < 675 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <LineStyled className="subHeaderLine" />
                </div>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={windowSize >= 675 ? 4 : 12}>
              <TextStyled className="subHeader">Durability</TextStyled>
              {windowSize < 675 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <LineStyled className="subHeaderLine" />
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <TextStyled className="subBody">
            At Fineseal Engineering, we have consistently upheld the principles
            of "Precision, Reliability, and Durability" as the cornerstones of
            our operations. We are fully committed to providing top-notch value
            and inspiring unwavering confidence in our clients.
          </TextStyled>
        </CustomContainer>
      </div>
      <Footer pageTopRef={pageTopRef} />
    </div>
  );
};

export default AboutUs;
