import React, { useEffect } from "react";
import { productItem } from "../../pages/Products/productList";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";

const service = {
  getData: (props: { from: number; to: number; products: productItem[] }) => {
    return new Promise((resolve, reject) => {
      const data = props.products.slice(props.from, props.to);
      resolve({
        count: props.products.length,
        data: data,
      });
    });
  },
};

const scrollToTop = (ref: any, smooth?: boolean) => {
  if (!ref.current) return;
  ref.current.scrollIntoView({ behavior: smooth ? "smooth" : "auto" });
};

const Mailto = (props: {
  email: string;
  subject: string;
  body: string;
  children: React.ReactNode;
  variant?: any;
  color?: any;
  style?: React.CSSProperties;
}) => {
  return (
    <a
      href={`mailto:${props.email}?subject=${
        encodeURIComponent(props.subject) || ""
      }&body=${encodeURIComponent(props.body) || ""}`}
      style={{ textDecoration: "none" }}
    >
      <Typography
        variant={props.variant}
        color={props.color}
        style={props.style}
      >
        {props.children}
      </Typography>
    </a>
  );
};

const SEO = (props: {
  title: string;
  description: string;
  name?: string;
  type?: string;
}) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta name="keywords" content="FINESEAL" />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      {/* <meta property="og:type" content={props.type} /> */}
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      {/* <meta name="twitter:creator" content={props.name} />
    <meta name="twitter:card" content={props.type} />
    <meta name="twitter:title" content={props.title} />
    <meta name="twitter:description" content={props.description} /> */}
      {/* End Twitter tags */}
    </Helmet>
  );
};

export { service, scrollToTop, Mailto, SEO };
