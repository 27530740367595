import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { themeColor } from "../../styles/Theme";

const TextStyled = styled(Typography)`
  &.header {
    padding-bottom: 20px;
    color: ${themeColor.grey};
    font-weight: 600;
    font-size: 35px;

    @media (min-width: 960px) {
      font-size: 40px;
    }

    @media (min-width: 1121px) {
      font-size: 45px;
    }
  }

  &.headerDes {
    padding-top: 15px;
    color: ${themeColor.blue};
    font-size: 25px;

    @media (min-width: 960px) {
      font-size: 30px;
    }

    @media (min-width: 1121px) {
      font-size: 35px;
    }
  }

  &.body {
    font-size: 16px;
    color: ${themeColor.lightBlack};
    line-height: 2;
    padding-bottom: 1.25rem;

    @media (min-width: 960px) {
      font-size: 18px;
      padding-bottom: 1.5rem;
    }
  }

  &.subHeader {
    padding-bottom: 10px;
    color: #333;
    font-weight: 600;
    text-align: center;
    font-size: 25px;

    @media (min-width: 675px) {
      border-bottom: 2px solid ${themeColor.grey};
      padding-bottom: 15px;
    }

    @media (min-width: 960px) {
      font-size: 30px;
      padding-bottom: 1.25rem;
    }

    @media (min-width: 1121px) {
      font-size: 35px;
    }
  }

  &.subBody {
    text-align: center;
    color: black;
    line-height: 2;
    font-size: 16px;
    padding-top: 20px;

    @media (min-width: 960px) {
      font-size: 18px;
      padding-top: 1.75rem;
    }
  }
`;

const LineStyled = styled.div`
  &.headerLine {
    height: 3px;
    width: 50px;
    background-color: ${themeColor.blue};
  }

  &.subHeaderLine {
    height: 2px;
    width: 30px;
    background-color: ${themeColor.grey};
  }
`;

const BoxStyled = styled(Box)`
  &.header {
    padding: 20px 0;

    @media (min-width: 960px) {
      padding: 40px 0;
    }

    @media (min-width: 1121px) {
      padding-top: 80px;
      padding-bottom: 40px;
    }
  }

  &.body {
    padding-bottom: 20px;

    @media (min-width: 960px) {
      padding-bottom: 40px;
    }

    @media (min-width: 1121px) {
      padding-bottom: 80px;
    }
  }
`;

export { TextStyled, LineStyled, BoxStyled };
