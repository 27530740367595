import { Stack } from "@mui/material";
import React from "react";
import { BreadcrumbStyled, LinkStyled } from "./styles";
import { NavigateNext } from "@mui/icons-material";
import { productItem } from "../../pages/Products/productList";
import { paths } from "../../configs/routes";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../services/hooks";

interface CustomBreadcrumbProps {
  item: productItem;
  prevRoute: string;
  pageTopRef: any;
}

const CustomBreadcrumb: React.FC<CustomBreadcrumbProps> = ({
  item,
  prevRoute,
  pageTopRef,
}) => {
  const navigate = useNavigate();

  const handleLink = (path: string) => {
    navigate(path);
  };

  return (
    <Stack>
      <BreadcrumbStyled
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
      >
        <LinkStyled
          underline="hover"
          key="1"
          color="inherit"
          onClick={() => {
            handleLink("/" + paths.products);
            scrollToTop(pageTopRef);
          }}
        >
          Products
        </LinkStyled>
        <LinkStyled
          underline="hover"
          key="2"
          color="inherit"
          onClick={() => {
            handleLink(prevRoute);
            scrollToTop(pageTopRef);
          }}
        >
          {item.category}
        </LinkStyled>
      </BreadcrumbStyled>
    </Stack>
  );
};

export default CustomBreadcrumb;
