import React, { useState, useEffect } from "react";
import {
  SliderContainer,
  LeftArrowStyled,
  RightArrowStyled,
  SlideDots,
  SlideDot,
} from "./styles";

interface ImageSliderProps {
  slides: JSX.Element[];
}

const ImageSlider: React.FC<ImageSliderProps> = ({ slides }) => {
  const [currIdx, setCurrIdx] = useState(0);

  // const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // const resetTimer = () => {
  //   if (timerRef.current) {
  //     clearTimeout(timerRef.current);
  //   }
  // };

  // useEffect(() => {
  //   resetTimer();
  //   timerRef.current = setTimeout(
  //     () =>
  //       setCurrIdx((prevIdx) =>
  //         prevIdx === slides.length - 1 ? 0 : prevIdx + 1
  //       ),
  //     3500
  //   );
  //   return () => {
  //     resetTimer();
  //   };
  // }, [currIdx, slides.length]);

  useEffect(() => {
    const autoPlay = setInterval(() => {
      goToNext();
    }, 3500);
    return () => {
      clearInterval(autoPlay);
    };
  }, [currIdx]);

  const goToPrev = () => {
    const isFirst = currIdx === 0;
    const newIdx = isFirst ? slides.length - 1 : currIdx - 1;
    setCurrIdx(newIdx);
  };

  const goToNext = () => {
    const isLast = currIdx === slides.length - 1;
    const newIdx = isLast ? 0 : currIdx + 1;
    setCurrIdx(newIdx);
  };

  return (
    <SliderContainer>
      <LeftArrowStyled fontSize="large" onClick={goToPrev} />
      <RightArrowStyled fontSize="large" onClick={goToNext} />
      <SlideDots>
        {slides.map((_, index) => (
          <SlideDot
            key={index}
            className={currIdx === index ? "active" : ""}
            onClick={() => setCurrIdx(index)}
          />
        ))}
      </SlideDots>
      {slides[currIdx]}
    </SliderContainer>
  );
};

export default ImageSlider;
