import { Card, CardActionArea, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { themeColor } from "../../styles/Theme";
import { KeyboardBackspace } from "@mui/icons-material";
import { scaleUp } from "../../utilities/common";

const CardStyled = styled(Card)`
  border-radius: 0;
  // box-shadow: 0rem 0.1rem 0.5rem 0rem ${themeColor.grey};
  box-shadow: none;
  margin: 0rem;
  background-color: ${themeColor.lightGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ImgDiv = styled.div`
  object-fit: contain;

  img {
    width: 100%;
    height: 100%;
  }
`;

const TextDiv = styled.div`
  position: absolute;
  // transform: translateX(-50%);
  transform: translate(-50%, -50%);

  &.app {
    color: white;
    top: 50%;
    left: 50%;
    text-align: center;
  }
`;

const ArrowStyled = styled(KeyboardBackspace)`
  padding-top: 1.25rem;
  color: ${themeColor.grey};
  transform: scaleX(-1);

  @media (min-width: 750px) {
    padding-top: 1.5rem;
  }
`;

const TextStyled = styled(Typography)`
  &.prodHeader {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    font-size: 18px;

    @media (min-width: 750px) {
      font-size: 20px;
    }

    @media (min-width: 960px) {
      font-size: 18px;
    }

    @media (min-width: 1121px) {
      font-size: 20px;
    }

    @media (min-width: 1345px) {
      font-size: 22px;
    }
  }

  &.prodBody {
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    font-size: 14px;
    color: ${themeColor.darkGrey};

    @media (min-width: 750px) {
      -webkit-line-clamp: 4;
      font-size: 15px;
    }

    @media (min-width: 960px) {
      font-size: 14px;
    }

    @media (min-width: 1121px) {
      font-size: 15px;
    }

    @media (min-width: 1345px) {
      font-size: 16px;
    }
  }

  &.appBody {
    font-size: 15px;

    @media (min-width: 960px) {
      font-size: 16px;
    }

    @media (min-width: 1121px) {
      font-size: 18px;
    }
  }
`;

const CardActionAreaStyled = styled(CardActionArea)`
  &.MuiCardActionArea-root {
    height: 15rem;
    background-color: white;
    text-wrap: pretty;

    @media (min-width: 675px) {
      height: 18rem;
    }

    @media (min-width: 750px) {
      height: 20rem;
    }

    @media (min-width: 850px) {
      height: 24rem;
    }

    @media (min-width: 960px) {
      height: 15rem;
    }

    @media (min-width: 1345px) {
      height: 18rem;
    }

    &:hover {
      color: ${themeColor.blue};

      // .MuiCardActionArea-focusHighlight {
      //   opacity: 0;
      // }

      img {
        animation: ${scaleUp} 300ms ease-out 1 forwards;
      }
    }
  }
`;

export {
  CardStyled,
  TextDiv,
  ArrowStyled,
  ImgDiv,
  TextStyled,
  CardActionAreaStyled,
};
