import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import styled from "@emotion/styled";
import { themeColor } from "../../styles/Theme";

const SliderContainer = styled.div`
  height: auto;
  position: relative;

  img {
    width: 100%;
    heigth: auto;
  }
`;

const LeftArrowStyled = styled(KeyboardArrowLeft)`
  position: absolute;
  padding: 0.75rem;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  left: 6.5rem;
  color: ${themeColor.blue};
  border: 2px solid ${themeColor.grey};
  border-radius: 50%;

  &:hover {
    color: ${themeColor.red};
    border-color: ${themeColor.darkRed};
  }
`;

const RightArrowStyled = styled(KeyboardArrowRight)`
  position: absolute;
  padding: 0.75rem;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  right: 6.5rem;
  color: ${themeColor.blue};
  border: 2px solid ${themeColor.grey};
  border-radius: 50%;

  &:hover {
    color: ${themeColor.red};
    border-color: ${themeColor.darkRed};
  }
`;

const SlideDot = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 4rem;
  height: 0.3rem;
  box-shadow: 0rem 0.1rem 0.5rem 0rem ${themeColor.lightGrey};
  background-color: ${themeColor.whiteGrey};
  z-index: 10;
  margin-right: 0.75rem;

  &.active {
    background-color: ${themeColor.darkGrey};
  }
`;

const SlideDots = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0.75rem;
  transform: translate(-50%, -0.75rem);
`;

const ImageDiv = styled.div`
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }

  .slideActive {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
`;

export {
  SliderContainer,
  LeftArrowStyled,
  RightArrowStyled,
  SlideDot,
  SlideDots,
  ImageDiv,
};
