import React, { useEffect, useState } from "react";
import { Box, Drawer, IconButton, Toolbar } from "@mui/material";
import {
  AppBarStyled,
  ButtonStyled,
  DrawerPaper,
  LogoStyled,
  ToolbarStyled,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { paths } from "../../configs/routes";
import menuItems from "./menuItems";
import MenuIcon from "@mui/icons-material/Menu";
import ShowDrawer from "./showDrawer";
import { Close } from "@mui/icons-material";

const Header = () => {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBarStyled
      // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <ToolbarStyled>
          <Box
            sx={{
              flexGrow: windowSize >= 1377 ? "" : 1,
              justifyContent: windowSize >= 1377 ? "center" : "",
              display: windowSize >= 1377 ? "none" : "flex",
            }}
          >
            <LogoStyled onClick={() => navigate("/" + paths.home)} />
          </Box>
          <Box
            sx={{
              flexGrow: windowSize >= 1377 ? 1 : 0,
              display: { xs: "none", md: "flex" },
              justifyContent: windowSize >= 1377 ? "center" : "",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: windowSize < 1377 ? "none" : "flex" }}>
              <LogoStyled onClick={() => navigate("/" + paths.home)} />
            </Box>
            {menuItems.map((item, index) => (
              <ButtonStyled
                key={index}
                disableRipple
                onClick={() => navigate(item.route)}
              >
                {item.name}
              </ButtonStyled>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              style={{ color: "#2a3c67", padding: 0 }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </ToolbarStyled>
      </AppBarStyled>
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{ component: DrawerPaper }}
      >
        <Toolbar
          style={{
            display: "inline-flex",
            justifyContent: "right",
          }}
        >
          <Close onClick={handleDrawerToggle} />
        </Toolbar>
        <ShowDrawer drawerHandler={handleDrawerToggle} />
      </Drawer>
    </>
  );
};

export default Header;
