import styled from "@emotion/styled";
import { themeColor } from "../../styles/Theme";
import {
  AppBar,
  Button,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { Logo } from "../Image";

const AppBarStyled = styled(AppBar)`
  && {
    background-color: white;
    position: static;
    box-shadow: 0rem 0.4rem 0.5rem 0rem ${themeColor.whiteGrey};
    // max-height: 7rem;
  }
`;

const ToolbarStyled = styled(Toolbar)`
  &.MuiToolbar-root {
    padding: 0 1.875rem;

    @media screen and (min-width: 960px) {
      padding: 0 3.125rem;
    }

    @media screen and (min-width: 1231px) {
      padding: 0 5rem;
    }

    @media screen and (min-width: 1345px) {
      padding: 0 6.25rem;
    }
  }
`;

const LogoStyled = styled(Logo)`
  max-height: 2.5rem;
  cursor: pointer;

  @media (min-width: 600px) {
    max-height: 3rem;
  }

  @media (min-width: 960px) {
    max-height: 4rem;
  }

  @media (min-width: 1377px) {
    margin: 0 2rem;
  }
`;

const ButtonStyled = styled(Button)`
  && {
    display: block;
    font-size: 15px;
    font-weight: 600;
    color: black;
    border-radius: 0rem;
    padding: 2rem 0rem;
    margin: 0rem 2rem;
    text-transform: none;

    &:hover {
      background-color: transparent;
      // color: white;
      padding-bottom: 1.8rem;
      border-bottom: 0.2rem solid ${themeColor.blue};
    }

    @media (mind-width: 1121px) {
      font-size: 18px;
    }
  }
`;

const TextStyled = styled(Typography)`
  font-size: 15px;
  font-weight: 600;
`;

const DrawerPaper = styled(Paper)`
  && {
    color: #c2c2c2;
    background-color: #171b1d;
  }
`

export {
  AppBarStyled,
  ButtonStyled,
  LogoStyled,
  TextStyled,
  ToolbarStyled,
  DrawerPaper
};
