import { Button } from "@mui/material";
import styled from "@emotion/styled";

const ButtonStyled = styled(Button)`
  && {
    // text-transform: none;

    &.MuiButton-root {
      width: 10rem;
      padding: 0.75rem 0rem;
    }
  }
`;

export { ButtonStyled };
