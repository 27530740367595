import React from "react";
import { AccStyled, AccSumStyled, TextStyled } from "./styles";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import { productMenuItem } from "./productMenuItems";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../services/hooks";

interface ProductMenuProps {
  item: productMenuItem;
  // expanded: string | false;
  expanded: string;
  // setExpanded: Dispatch<SetStateAction<string | false>>;
  pageRef: any;
}

const ProductMenu: React.FC<ProductMenuProps> = ({
  item,
  expanded,
  // setExpanded,
  pageRef,
}) => {
  // const handleChange =
  //   (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded(isExpanded ? panel : expanded === panel ? panel : false);
  //   };

  const navigate = useNavigate();

  const handleLink = (path: string) => {
    navigate(path);
  };

  return (
    <div>
      <AccStyled
        disableGutters
        elevation={0}
        square
        expanded={expanded === item.id}
        // onChange={handleChange(item.id)}
        onClick={() => {
          handleLink(item.route);
          scrollToTop(pageRef, true);
        }}
      >
        <AccSumStyled
          expandIcon={
            <ArrowForwardIosSharp
              sx={{ fontSize: "0.9rem" }}
              style={{ color: expanded === item.id ? "#476ab2" : "" }}
            />
          }
          aria-controls={item.id + "-content"}
          id={item.id + "-header"}
          style={{ color: expanded === item.id ? "#476ab2" : "" }}
        >
          <TextStyled className="menuContent">{item.name}</TextStyled>
        </AccSumStyled>
      </AccStyled>
    </div>
  );
};

export default ProductMenu;
