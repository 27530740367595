import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import CustomContainer from "../../components/CustomContainer";
import ProductMenu from "./productMenu";
import productMenuItems from "./productMenuItems";
import { ProductCenterDiv, TextStyled } from "./styles";
import { Grid, Stack } from "@mui/material";
import {
  conventionalKettles,
  mechanicalSeals,
  modularKettles,
  onePieceKettles,
  productItems,
  specialKettles,
} from "./productList";
import { ProductHeaderImg } from "../../components/Image";
import ShowProducts from "./showProducts";
import Footer from "../../components/Footer";
import { SEO } from "../../services/hooks";

interface ProductsProps {
  expanded: string;
}

const Products: React.FC<ProductsProps> = ({ expanded }) => {
  // const [expanded, setExpanded] = useState<string | false>("0");
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const pageTopRef = useRef(null);
  const pageMidRef = useRef(null);

  const category =
    expanded === "1"
      ? conventionalKettles
      : expanded === "2"
      ? modularKettles
      : expanded === "3"
      ? onePieceKettles
      : expanded === "4"
      ? specialKettles
      : expanded === "5"
      ? mechanicalSeals
      : productItems;

  return (
    <Stack>
      <SEO
        title="Products"
        description="Conventional kettles, Modular kettles, One-piece belt frame for kettle, Special working condition design, Mechanical seal auxiliary system"
      />
      <div style={{ zIndex: 100 }} ref={pageTopRef}>
        <Header />
      </div>
      {/* <ProductHeaderImg /> */}
      <div
        style={{ width: "100%", background: "#f0f0ef", position: "relative" }}
      >
        <CustomContainer className="ProductPage">
          <Grid container spacing={windowSize >= 1231 ? 5 : 4}>
            <Grid
              item
              xs={
                windowSize >= 1121
                  ? 3
                  : windowSize >= 960 && windowSize < 1121
                  ? 4
                  : 12
              }
            >
              <ProductCenterDiv>
                <TextStyled className="header">Product</TextStyled>
                <TextStyled className="subHeader">Center</TextStyled>
              </ProductCenterDiv>
              {productMenuItems.map((item, index) => (
                <ProductMenu
                  item={item}
                  key={index}
                  expanded={expanded}
                  // setExpanded={setExpanded}
                  pageRef={pageMidRef}
                />
              ))}
            </Grid>
            <Grid
              item
              xs={
                windowSize >= 1121
                  ? 9
                  : windowSize >= 960 && windowSize < 1121
                  ? 8
                  : 12
              }
              ref={pageMidRef}
            >
              <ShowProducts
                category={category}
                uniqueKey={expanded}
                pageRef={pageMidRef}
                pageTopRef={pageTopRef}
              />
            </Grid>
          </Grid>
        </CustomContainer>
      </div>
      <Footer pageTopRef={pageTopRef} />
    </Stack>
  );
};

export default Products;
